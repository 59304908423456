<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <div class="d-flex align-center">
            <bullet :title="'List Expenditure'" class="mr-2" />
            <v-menu transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  @click="importExport(import_data)"
                  class="bg_color1 rounded-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="d-flex align-center text-capitalize">
                    <v-icon class="mr-1">
                      {{ import_data ? "mdi-import" : "mdi-export" }}
                    </v-icon>
                    {{ import_data ? "Import" : "Export" }}
                    <v-icon class="ml-1"> mdi-menu-down </v-icon>
                  </div>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="px-2">
                  <v-btn
                    block
                    depressed
                    @click="changeFunction('export')"
                    class="rounded-lg"
                  >
                    <div class="d-flex align-center text-capitalize">
                      <v-icon class="mr-1">mdi-export</v-icon>
                      Export
                    </div>
                  </v-btn>
                </v-list-item>
                <v-list-item class="px-2">
                  <v-btn
                    :disabled="
                      role != 'USER' && role != 'ASSOCIATE' ? false : true
                    "
                    block
                    depressed
                    @click="changeFunction('import')"
                    class="rounded-lg"
                  >
                    <div class="d-flex align-center text-capitalize">
                      <v-icon class="mr-1">mdi-import</v-icon>
                      Import
                    </div>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                @click="createData"
              >
                <v-icon class="mr-2"> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">
                      {{
                        sort
                          ? sort == "tgl_transaksi"
                            ? "Tanggal Transaksi"
                            : "ID Expenditure"
                          : "Sort"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize"
                        text
                      >
                        <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                        Sort
                      </v-btn>
                    </div>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'tgl_transaksi'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'tgl_transaksi' ? true : false"
                      text
                      @click="changeSort('tgl_transaksi')"
                    >
                      Tanggal Transaksi
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'id'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'id' ? true : false"
                      text
                      @click="changeSort('id')"
                    >
                      ID Expenditure
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
          :single-select="false"
        >
          <template v-slot:[`item.beban.nama_beban`]="{ item }">
            <div v-if="item.beban" class="text-capitalize">
              {{ item.beban.nama_beban }}
            </div>
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            <div v-if="item.cost" class="d-flex">
              Rp.
              {{ formatRupiah(item.cost) }}
            </div>
          </template>
          <template v-slot:[`item.total_biaya`]="{ item }">
            <div v-if="item.total_biaya" class="d-flex">
              Rp.
              {{ formatRupiah(item.total_biaya) }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-card
              class="pa-2 text-center"
              flat
              dark
              :color="item.status == 1 ? '#26AF65' : '#DC3545'"
            >
              {{ item.status == 1 ? "Sudah Dibayar" : "Belum Dibayar" }}
            </v-card>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Expenditure</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Delete Expenditure</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <dialog-large
        :d_large="d_large"
        :type="d_type"
        @close="d_large = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listExpenditure",
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      menuLimit: false,
      find: "",
      page: 1,
      limit: 10,
      dataSet: null,
      last_page: null,
      total_page: null,
      import_data: false,
      loading: false,
      d_large: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      sort: "",
      sort_menu: false,
      icon_sort: "mdi-sort-ascending",
      menuCustom_date: false,
      custom_date: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Expenditure",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Tanggal", value: "tgl_transaksi" },
        { text: "ID", value: "id", sortable: false },
        {
          text: "Transaksi",
          value: "nama_transaksi",
        },
        {
          text: "Beban",
          value: "beban.nama_beban",
        },
        {
          text: "Kuantitas",
          value: "qty",
          sortable: false,
        },
        { text: "Cost", value: "cost" },
        { text: "Total Tagihan", value: "total_biaya" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // ubah jadi format uang indonesia
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },

    // get list expenditure
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/expenditure?page[size]=${this.limit}&page[number]=${
          this.page
        }&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}&filter[nama_transaksi]=${this.find}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.dataSet = data.data.data;
          this.last_page = data.data.last_page;
          this.total_page = data.data.total_page;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // ubah tombol export/import
    changeFunction(item) {
      if (item == "export") {
        this.downloadeExport();
        this.importExport(item);
        this.import_data = false;
      } else {
        this.importExport(item);
        this.import_data = true;
      }
    },

    // pop up import expenditure
    importExport(item) {
      if (item == "import") {
        this.inventory = [];
        this.d_type = "i_expenditure";
        this.d_title = "Import Expenditure";
        this.d_large = true;
        this.line = true;
      }
    },

    // download export expenditure
    downloadeExport() {
      let data = {
        path: `sysadmin/expenditure/export`,
        body: "",
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          window.open(data.data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },

    // ubah sorting berdasarkan
    changeSort(item, add) {
      if (item == "custom_date" && !add) {
        this.menuCustom_date = true;
      } else if (item == "custom_date" && add == "hide") {
        this.menuCustom_date = false;
      }
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },

    // ubah sorting terlama atau terbaru
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },

    // tampilkan pop up tambah data expenditure
    createData() {
      this.d_type = "c_expenditure";
      this.d_title = "Tambah Expenditure";
      this.d_create = true;
      this.line = true;
    },

    // tampilkan pop up update data expenditure
    updateData(item) {
      item.total_biaya = parseInt(item.total_biaya);
      item.cost = parseInt(item.cost);
      this.inventory = item;
      this.d_type = "u_expenditure";
      this.d_title = "Edit Expenditure";
      this.d_create = true;
      this.line = true;
    },

    // tampilkan pop up delete data expenditure
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/expenditure/delete`,
        deleteType: "Expenditure",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Expenditure";
      this.d_small = true;
      this.line = true;
    },

    // ubah limit data yg tampil per page
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
